import React, { useEffect, useMemo, useRef, useState } from "react";
import FroalaEditor from "react-froala-wysiwyg";
import { useSelector } from "react-redux";
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import { IntlService } from "../../wasfaty/services";

const FROALA_KEY = 're1H1qB1C2D6E7A5E5G4iAa1Tb1YZNYAh1CUKUEQOHFVANUqD1G1F4C3B1C8C7D2C4G4==';

const RTLFroalaEditor = ({ setValue, value }) => {
  console.log(value,"value");
  
  // const isArabic = IntlService.isRtl; // You might want to determine this dynamically
  const editor = useRef(null);
  const [isArabic, setIsArabic] = useState(IntlService.isRtl);
  

  const containsArabic = (text) => {
    const arabicRegex = /[\u0600-\u06FF]/;
    return arabicRegex.test(text);
  };

  useEffect(() => {
    if (value) {
      setIsArabic(containsArabic(value) || IntlService.isRtl);
    }
  }, [value]);

  const config = useMemo(
    () => ({
      key: FROALA_KEY,
      height: 200,
      charCounterCount: true,
      direction: isArabic ? "rtl" : "ltr",
      tooltips: false,
      toolbarInline: false,
      language: isArabic ? "ar" : "en",
      htmlExecuteScripts: false,
      htmlAllowedTags: ['.*'],
      htmlAllowedAttrs: ['.*'],
      htmlDoNotWrapTags: ['script', 'style'],
      htmlUntouched: true,
      enter: FroalaEditor.ENTER_BR,
      toolbarButtons: {
        moreText: {
          buttons: [
            "bold", "italic", "underline", "strikeThrough", "subscript", "superscript",
            "fontFamily", "fontSize", "textColor", "backgroundColor", "inlineStyle", "clearFormatting"
          ],
          buttonsVisible: 12
        },
        moreParagraph: {
          buttons: [
            "alignLeft", "alignCenter", "formatOLSimple", "alignRight", "alignJustify",
            "formatOL", "formatUL", "paragraphFormat", "paragraphStyle", "lineHeight", "outdent", "indent"
          ],
          buttonsVisible: 12
        },
        moreRich: {
          buttons: [
            "insertLink", "insertImage", "insertVideo", "insertTable", "emoticons",
            "fontAwesome", "specialCharacters", "embedly", "insertFile", "insertHR"
          ],
          buttonsVisible: 12
        },
        moreMisc: {
          buttons: [
            "undo", "redo", "fullscreen", "print", "getPDF", "spellChecker", "selectAll", "html", "help"
          ],
          align: "right",
          buttonsVisible: 4
        }
      },
      events: {
        'froalaEditor.initialized': function (e, editor) {
          editor.html.set(wrapWithRTL(editor.html.get()));
        },
        'froalaEditor.contentChanged': function (e, editor) {
          editor.html.set(wrapWithRTL(editor.html.get()));
        }
      }
    }),
    [isArabic]
  );

  const wrapWithRTL = (content) => {
    if (isArabic && content && !content.includes('dir="rtl"')) {
      return `<div dir="rtl" style="text-align: right;">${content}</div>`;
    }
    return content;
  };

  const handleModelChange = (model) => {
    const wrappedContent = wrapWithRTL(model);
    setValue(wrappedContent);
  };

  return (
    <FroalaEditor
      ref={editor}
      model={value}
      config={config}
      onModelChange={handleModelChange}
    />
  );
};

export default RTLFroalaEditor;


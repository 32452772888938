import { makeAutoObservable } from "mobx";
// import { makePersistable, getPersistedStore } from "mobx-persist-store";
import { SiteService, UserService } from "../services";
import get from "lodash/get";
// import localForage from "localforage";

export class UserPresistModal {
  users = [];
  page = 0;
  per_page = 20;
  current_page = 0;
  last_page = 1;
  total = 0;
  isLoading = false;

  lastSearch = "";

  constructor(type) {
    this.type = type;
    this.getUsers();
    makeAutoObservable(this);
  }

  reset = () => {
    this.page = 0;
    this.current_page = 0;
    this.last_page = 1;
    this.users.splice(0, this.users.length);
  };

  isSearchBlocked = (search) => {
    const isSearchEqual = this.lastSearch === search;
    let check = isSearchEqual && search != "";

    if (!isSearchEqual) {
      this.reset();
    }

    return check;
  };

  getUsers = async (search = "") => {
    let userData = [];
    if (!this.isSearchBlocked(search)) {
      this.page = this.page + 1;
      this.lastSearch = search;

      try {
        const res = await UserService.find(
          {
            per_page: this.per_page,
            page: this.page,
            search,
          },
          this.type
        );

        const data = get(res, "data.data", []);
        const _users = get(data, "data", []);
        if (_users?.length) {
          userData = _users.map((item) => ({
            ...item,
            label: item.name + ` (${item.email}) `,
            value: item._id,
          }));
          this.users.push(...userData);
        }

        if (data) {
          this.total = data.total;
          this.last_page = data.last_page;
        }
      } catch (error) {}
    }

    return {
      options: userData,
      hasMore: this.hasMore,
    };
  };

  hasMore = () => {
    return this.total > this.users?.length || 0;
  };
}

import { useLocation } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle, // Added Spinner from reactstrap
} from "reactstrap";
import Spinner from "../../@core/components/spinner/Fallback-spinner";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import "./newStyle.scss";
import { SC } from "../wasfaty/Api/serverCall";
import { FaExclamationCircle } from "react-icons/fa";

const Sso = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const location = useLocation();

  // Function to get query parameters from the URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    if (code) {
      setLoading(true); // Set loading state

      // Direct API call inside useEffect
      SC.getCall({ url: `seha/callback?code=${code}` }) // Send code as a query parameter in the URL
        .then((response) => {
          if (response.status === 200 && response.data) {
            localStorage.setItem(
              "accessToken",
              JSON.stringify(response?.data?.accessToken)
            );

            localStorage.setItem(
              "refreshToken",
              JSON.stringify(response?.data?.refreshToken)
            );

            SC.getCall({ url: "token" }).then((res) => {
              localStorage.setItem(
                "userData",
                JSON.stringify(res.data.data.user[0])
              );
              setLoading(false);
              window.location.reload(false);
            });
            // setVisible(false);
          } else {
            setError(response?.response?.data?.error || "Failed to fetch data");
            // toast.error(
            //   response?.response?.data?.error || "Failed to fetch data"
            // );
          }
        })
        .catch((error) => {
          toast.error("Error occurred while fetching data");
          console.error(error);
        })
        .finally(() => {
          setLoading(false); // End loading state
        });
    } else {
      fetchLink();
    }
  }, []);

  const fetchLink = () => {
    localStorage.clear();
    SC.getCall({ url: "login/seha" })
      .then((response) => {
        if (response.status === 200 && response.data) {
          const link = response.data.link;
          if (link) {
            // window.open(link); // Open the link in a new tab
            window.location.href = link; // Redirect to the link
          } else {
            toast.error("Invalid SSO");
          }
        } else {
          toast.error(response?.response?.data?.error || "Failed to get link");
        }
      })
      .catch((error) => {
        toast.error("Error occurred while fetching the link");
      });
  };

  return (
    <div
      className="authLayout_container d-flex justify-content-center align-items-center"
      style={{
        height: "100vh",
        width: "100vw",
        backgroundColor: "#f8f9fa",
      }} // Full screen
    >
      {error && (
        <Card
          style={{
            width: 400,
            justifyItems: "center",
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
          }}
        >
          <CardBody>
            <CardTitle tag="h3">
              <FaExclamationCircle
                className="error-icon"
                style={{ color: "red", marginRight: 10 }}
              />
              Error
            </CardTitle>
            <h6 className="error-message">{error}</h6>
          </CardBody>
        </Card>
      )}
      {!error && <Spinner animation="border" role="status" />}
    </div>
  );
};

export default Sso;
